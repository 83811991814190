import axios, { AxiosResponse } from "axios";
import { CreateConcertData, Dictionary } from "../global/types";

export const createConcert = async (data: CreateConcertData): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/concert/create`;
    return axios.post(url, data);
};

export const updateConcertData = async (data: CreateConcertData): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/concert/edit`;
    return axios.post(url, data);
}

export const getAllConcertData = async (params: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/concert/list`;
    return axios.get(url, { params });
}

export const getOneConcertData = async (id: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/concert/details/${id}`;
    return axios.get(url);
}

export const updateTrackSplitData = async (concertId: string, data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/concert/track-splitting/update/${concertId}`;
    return axios.post(url, data);
}

export const updateGallery = async (concertId: string, data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/concert/gallery/update/${concertId}`;
    return axios.post(url, data);
}

export const getPreSignedUrlAudio = async (file_name: string, content_type: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/files/generate-presigned-audio`;
    const data = { file_name, content_type };
    return axios.post(url, data);
}

export const getPreSignedUrlImage = async (files: Array<{file_name: string, content_type: string}>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/files/generate-presigned-images`;
    return axios.post(url, { gallery: files });
}

export const uploadFileToS3 = async (url: string, file: File, contentType: string): Promise<AxiosResponse> => {
    const axiosWithoutAuth = axios.create();
    delete axiosWithoutAuth.defaults.headers.common['Authorization'];
    
    return axiosWithoutAuth.put(url, file, {
        headers: {
            "Content-Type": contentType,
        },
    });
}

export const getAllPricesForConcertData = async (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/concert/prices`;
    return axios.get(url);
}

export const verifyUniqueSlug = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/concert/verify-slug`;
    return axios.post(url, data);
}

export const toggleIsEnable = async (id: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/concert/toggle-enable`;
    return axios.post(url, {concert_id: id});
}

export const getTotalUsers = async (id: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/concert/purchased/users/${id}`;
    return axios.get(url);
}
