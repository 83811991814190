import React, { useContext } from "react"
import { RootContext } from "../context"
import { Navigate } from "react-router-dom";

interface ProtectedRoutesProps {
  children: React.ReactNode;
}

const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({children}) => {
  const { authStore: { isSuperAdmin } } = useContext(RootContext);

  if (!isSuperAdmin()) {
    return <Navigate to="/" replace />;
  }
  
  return (
    <>
      {children}
    </>
  )
}

export default ProtectedRoutes
