import { makeAutoObservable, runInAction } from "mobx";
import { IArtistStore, Store, CreateArtistData, UserData, AllUserData } from "../global/types";
import { createArtist, getAllArtistData, getOneUserData, getPreSignedImageUrl, updateArtistData, uploadFileToS3 } from "../api/user.action";

class ArtistStore implements IArtistStore {
    rootStore: Store;
    allArtistData: AllUserData[] = [];
    artistData: UserData | null = null;
    error: string | null = null;
    loading: boolean = false;
    page: number = 1;
    searchTerm: string = '';
    limit: number = 10;
    totalItems: number = 0;
    totalPages: number = 0;

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    fetchAllArtistData = async (
        action: 'change_page' | 'search' | 'change_limit' | '',
        page: number,
        searchTerm: string,
        limit: number
    ) => {
        this.setLoading(true);
        try {
            runInAction(() => {
                switch (action) {
                    case 'change_page':
                        this.page = page;
                        break;
                    case 'search':
                        this.searchTerm = searchTerm;
                        this.page = 1;
                        break;
                    case 'change_limit':
                        this.limit = limit
                        this.page = 1
                        break;
                    default:
                        break;
                }
            })
            const data = {
                page: this.page,
                limit: this.limit,
                search: this.searchTerm?.trim()
            }
            const response = await getAllArtistData(data);
            runInAction(() => {
                this.allArtistData = response.data?.data?.users || [];
                this.totalItems = response.data?.data?.totalItems;
                this.totalPages = response.data?.data?.totalPages;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    fetchOneArtistData = async (id: string) => {
        this.setLoading(true);
        try {
            const response = await getOneUserData(id);
            console.log("One Artist: ", response)
            runInAction(() => {
                this.artistData = response.data.data;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    updateArtistDetails = async (data: CreateArtistData) => {
        this.setLoading(true);
        try {
            const requestBody = {
                name: data.name,
                image: this.getUrlEndpoint(data.image),
                artist_id: this.artistData?._id
            }

            const response = await updateArtistData(requestBody);
            runInAction(() => {
                this.artistData = response.data.data;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    addNewArtist = async (data: CreateArtistData) => {
        this.setLoading(true);
        try {
            const requestBody = {
                name: data.name,
                image: data.image
            }
            const response = await createArtist(requestBody);
            runInAction(() => {
                this.artistData = response.data.data;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    resetArtistPageDataStates = () => {
        runInAction(() => {
            this.allArtistData = [];
            this.artistData = null;
            this.error = null;
            this.loading = false;
            this.page = 1;
            this.searchTerm = '';
            this.limit = 10;
            this.totalItems = 0;
            this.totalPages = 0;
        })
    }

    getUrlEndpoint = (fullUrl: string) => {
        try {
            const url = new URL(fullUrl);
            const endpoint = url.pathname.startsWith("/") ? url.pathname.slice(1) : url.pathname;
            return endpoint;
        } catch (error) {
            return fullUrl;
        }
    };

    uploadImage = async (file: File, user_type: string): Promise<string> => {
        try {
            const response = await getPreSignedImageUrl(file.name, file.type, user_type);
            const { presigned_url, file_name } = response.data.data;

            await uploadFileToS3(presigned_url, file, file.type);

            return file_name;
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
            throw error;
        }
    }
}

export default ArtistStore;
