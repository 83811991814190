import { makeAutoObservable, runInAction } from "mobx";
import { Store, IDashboardStore, AllTransactionsAndAmountCount } from "../global/types";
import { getAllTransactionsAndAmountCountData } from "../api/dashboard.action";

interface TransactionData {
    timePeriod: "day" | "week" | "all";
    totalAmount: number;
    transactionCount: number;
}
  
class DashboardStore implements IDashboardStore {
    rootStore: Store;
    allTransactionsAndAmountCount: AllTransactionsAndAmountCount[] = [];
    error: string | null = null;
    loading: boolean = false; 

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    fetchAllTransactionsAndAmountCount = async () => {
        this.setLoading(true);
        try {
            const response = await getAllTransactionsAndAmountCountData()
            runInAction(() => {
                this.allTransactionsAndAmountCount = response.data?.data || [];
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    resetDashboardData = () => {
        runInAction(() => {
            this.allTransactionsAndAmountCount = [];
            this.error = null;
            this.loading = false; 
        
        })
    }
}

export default DashboardStore;
