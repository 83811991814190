import { makeAutoObservable, observable, runInAction } from "mobx";
import { IConcertStore, Store, ConcertData, Genre, Artist, Venue, CreateConcertData, TrackSplittingType, AllConcertData, PriceDataForConcert, verifySlugData, MediaItem } from "../global/types";
import { createConcert, getAllConcertData, getAllPricesForConcertData, getOneConcertData, getPreSignedUrlAudio, getPreSignedUrlImage, getTotalUsers, toggleIsEnable, updateConcertData, updateGallery, updateTrackSplitData, uploadFileToS3, verifyUniqueSlug } from "../api/concert.action";
import { getOneUserData } from "../api/user.action"
class ConcertStore implements IConcertStore {
    rootStore: Store;
    allConcertData: AllConcertData[] = [];
    concertData: ConcertData | null = null;
    trackSplitting: TrackSplittingType[] = [];
    gallery: MediaItem[] = [];
    priceDataForConcert: PriceDataForConcert[] = []
    error: string | null = null;
    loading: boolean = false;
    page: number = 1;
    searchTerm: string = '';
    limit: number = 10;
    concertType: string = 'All';
    concertStatus: string = 'All';
    totalItems: number = 0;
    totalPages: number = 0;
    audioUploadProgress: number = 0;
    isAudioUploading: boolean = false;
    audioUploadComplete: boolean = false;
    audioURL: string | null = null;
    concertDuration: string | null = null;
    isEnable: boolean | null = null
    @observable public slugAvailable: boolean = false;
    @observable public fullAudioUrl: string | null = null

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    fetchAllConcertData = async (
        action: 'change_page' | 'search' | 'change_filter' | '',
        page: number,
        searchTerm: string,
        limit: number,
        concertType: string,
        concertStatus: string,
        isEnable: boolean | null
    ) => {
        this.setLoading(true);
        try {
            runInAction(() => {
                switch (action) {
                    case 'change_page':
                        this.page = page;
                        break;
                    case 'search':
                        this.searchTerm = searchTerm;
                        this.page = 1;
                        break;
                    case 'change_filter':
                        this.limit = limit;
                        this.concertType = concertType;
                        this.concertStatus = concertStatus;
                        this.isEnable = isEnable;
                        this.page = 1;
                        break;
                    default:
                        break;
                }
            });
            const params: any = {
                page: this.page,
                limit: this.limit,
                search: this.searchTerm,
                concert_type: this.concertType.toUpperCase(),
                concert_status: this.concertStatus.toUpperCase(),
            }
            if (isEnable !== null || isEnable !== undefined) {
                params['is_enable'] = isEnable
            }
            const response = await getAllConcertData(params);
            runInAction(() => {
                this.allConcertData = response.data?.data?.concerts || [];
                this.totalItems = response.data?.data?.totalItems;
                this.totalPages = response.data?.data?.totalPages;
            });
        } catch (error: any) {
            if (error?.response?.status !== 404) {
                this.rootStore.authStore.handleUnauthorized(error);
            }
        } finally {
            this.setLoading(false);
        }
    }

    fetchOneConcertData = async (id: string) => {
        this.setLoading(true);
        try {
            const response = await getOneConcertData(id);
            const data = response.data?.data;
            const formattedGenre = data.genre.map((genre: string) => ({
                value: genre,
                label: genre.charAt(0).toUpperCase() + genre.slice(1)
            }));

            const formattedArtist = data.credits.artists.map((artist: any) => {
                return { value: artist._id, label: artist.name };
            })
            const formattedPhotographer = data.credits.photographers.map((photographer: any) => {
                return { value: photographer._id, label: photographer.name };
            })
            const formattedVenue = data.credits.venues.map((location: any) => {
                return { value: location._id, label: location.name };
            })
            const formattedType = {
                value: data.type,
                label: data.type.charAt(0).toUpperCase() + data.type.slice(1).toLocaleLowerCase()
            }
            const date = new Date(data?.date);
            const formattedDate = date.toISOString().split('T')[0];
            runInAction(() => {
                this.concertData = {
                    ...data,
                    genre: formattedGenre,
                    artist: formattedArtist,
                    location: formattedVenue,
                    photographer: formattedPhotographer,
                    type: formattedType,
                    date: formattedDate
                };
                this.audioURL = data.file_url;
                this.concertDuration = data.length;
                this.fullAudioUrl = data.audio_url;
                this.trackSplitting = data.track_splitting || [];
                this.gallery = data.gallery || []
            });
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    updateConcertDetails = async (id: string, data: ConcertData) => {
        this.setLoading(true);
        try {
            const filteredData = this.filterEmptyFields(data);
            const requestBody: CreateConcertData = {
                ...filteredData as CreateConcertData,
                genre: data.genre.map((genre: Genre) => genre.value),
                // artist: data.artist.map((artist: Artist) => artist.value),
                // location: data.location.map((venue: Venue) => venue.value),
                type: data.type.value,
                file_url: this.getUrlEndpoint(this.audioURL!),
                length: this.concertDuration!,
                concert_id: id,
                is_enable: data.is_enable
            }
            const response = await updateConcertData(requestBody);
            runInAction(async () => {
                this.resetConcertData();
                const data = response.data?.data;
                const formattedGenre = data.genre.map((genre: string) => ({
                    value: genre,
                    label: genre.charAt(0).toUpperCase() + genre.slice(1)
                }));
                const formattedArtist = await Promise.all(data.credits.artists.map(async (artist: string) => {
                    const artist_res = await getOneUserData(artist);
                    const res = artist_res.data.data
                    return { value: res._id, label: res.name };
                }));
                const formattedPhotographer = await Promise.all(data.credits.photographers.map(async (photographer: string) => {
                    const photographer_res = await getOneUserData(photographer);
                    const res = photographer_res.data.data
                    return { value: res._id, label: res.name };
                }));
                const formattedVenue = await Promise.all(data.credits.venues.map(async (location: string) => {
                    const location_res = await getOneUserData(location);
                    const res = location_res.data.data
                    return { value: res._id, label: res.name };
                }));
                const formattedType = {
                    value: data.type,
                    label: data.type.charAt(0).toUpperCase() + data.type.slice(1).toLocaleLowerCase()
                }
                const date = new Date(data?.date);
                const formattedDate = date.toISOString().split('T')[0];
                runInAction(() => {
                    this.concertData = {
                        ...data,
                        genre: formattedGenre,
                        artist: formattedArtist,
                        location: formattedVenue,
                        photographer: formattedPhotographer,
                        type: formattedType,
                        date: formattedDate
                    };
                    this.audioURL = data.file_url;
                    this.concertDuration = data.length;
                    this.fullAudioUrl = data.audio_url;
                    this.trackSplitting = data.track_splitting || [];
                });
            });
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    addNewConcert = async (data: ConcertData): Promise<ConcertData> => {
        this.setLoading(true);
        try {
            const filteredData = this.filterEmptyFields(data);
            const requestBody: CreateConcertData = {
                ...filteredData as CreateConcertData,
                genre: data.genre.map((genre: Genre) => genre.value),
                type: data.type.value,
                file_url: this.getUrlEndpoint(this.audioURL!),
                length: this.concertDuration!,
                is_enable: data.is_enable
            }
            const response = await createConcert(requestBody);
            runInAction(() => {
                this.resetConcertData();
                this.concertData = response.data?.data;
            });
            return response.data?.data;
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

    resetConcertData = () => {
        this.concertData = null;
        this.trackSplitting = [];
        this.gallery = [];
        this.audioURL = null;
        this.concertDuration = null;
    }

    fetchSecretToken = async (file_name: string, content_type: string) => {
        try {
            const response = await getPreSignedUrlAudio(file_name, content_type);
            return response.data;
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
        }
    }


    uploadAudio = async (file: File): Promise<string> => {
        this.isAudioUploading = true;
        this.audioUploadProgress = 0;
        this.audioUploadComplete = false;
        try {
            const response = await getPreSignedUrlAudio(file.name, file.type);
            const { presigned_url, file_name } = response.data.data;

            await uploadFileToS3(presigned_url, file, file.type);

            runInAction(() => {
                this.audioUploadComplete = true;
                this.audioURL = file_name;
            });
            return file_name;
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
            throw error;
        } finally {
            runInAction(() => {
                this.isAudioUploading = false;
            });
        }
    }

    uploadImages = async (files: File[]): Promise<any> => {
        try {
            const allImages = files.map(file => ({ file_name: file.name, content_type: file.type }))
            const uploadedFileNames = await getPreSignedUrlImage(allImages);

            const uploadPromises = uploadedFileNames.data.data.map(async (image: { file_name: string, presigned_url: string, name: string }) => {
                const file = files.find(file => file.name === image.name);
                if (file) {
                    await uploadFileToS3(image.presigned_url, file, file.type);
                    return image.file_name;
                }
            });

            await Promise.all(uploadPromises);

            return uploadedFileNames.data.data;
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
            throw error;
        }
    };

    updateTrackSplit = async (data: TrackSplittingType[]) => {
        this.setLoading(true);
        try {
            const requestBody = {
                track_splitting: data.map((track, index) => {
                    return {
                        ...track,
                        split_end_time: undefined,
                    }
                })
            }
            const response = await updateTrackSplitData(this.concertData!._id!, requestBody);
            runInAction(() => {
                this.trackSplitting = response.data.data;
            })
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    updateGalleryMedia = async (data: MediaItem[]) => {
        this.setLoading(true);
        try {
            const requestBody = {
                gallery: data
            }
            const response = await updateGallery(this.concertData!._id!, requestBody);
            runInAction(() => {
                this.gallery = response.data.data;
            })
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    setConcertDuration = (duration: string) => {
        this.concertDuration = duration;
    }

    getUrlEndpoint = (fullUrl: string) => {
        try {
            const url = new URL(fullUrl);
            const endpoint = url.pathname.startsWith("/") ? url.pathname.slice(1) : url.pathname;
            return endpoint;
        } catch (error) {
            return fullUrl;
        }
    };

    fetchAllPriceForConcert = async () => {
        this.setLoading(true);
        try {
            const response = await getAllPricesForConcertData();
            runInAction(() => {
                this.priceDataForConcert = [
                    ...(response.data?.data?.length > 0
                        ? response.data.data.map((concert: { _id: string; price: number }) => ({
                            _id: concert._id,
                            title: `$${concert.price.toFixed(2)}`,
                        }))
                        : []
                    ),
                ]
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    checkSlugIsAvailable = async (slug: string, id?: string): Promise<void> => {
        try {
            const requestBody: verifySlugData = { slug, id }
            const response = await verifyUniqueSlug(requestBody);
            runInAction(() => {
                this.slugAvailable = response.data.success === 1
            });
            // return response.data?.data;
        } catch (error: any) {
            runInAction(() => {
                this.slugAvailable = false
            })
            this.rootStore.authStore.handleUnauthorized(error);
        }
    }

    filterEmptyFields = (obj: Record<string, any>) => {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) =>
            v !== undefined && v !== null && (typeof v === 'string' ? v.trim() : v)
        ));
    };

    toggleEnableConcert = async (id: string) => {
        try {
            await toggleIsEnable(id);
            await this.fetchAllConcertData('', this.page, this.searchTerm, this.limit, this.concertType, this.concertStatus, this.isEnable)
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
            throw error;
        }
    }

    resetConcertPageDataStates = () => {
        runInAction(() => {
            this.allConcertData = [];
            this.concertData = null;
            this.priceDataForConcert = []
            this.error = null;
            this.loading = false;
            this.page = 1;
            this.searchTerm = '';
            this.limit = 10;
            this.concertType = 'All';
            this.concertStatus = 'All';
            this.totalItems = 0;
            this.totalPages = 0;
            this.audioUploadProgress = 0;
            this.isAudioUploading = false;
            this.audioUploadComplete = false;
            this.audioURL = null;
            this.concertDuration = null;
            this.slugAvailable = false;
        })
    }

    fetchTotalUsers = async (id: string) => {
        try {
            const response = await getTotalUsers(id);
            return response.data?.data?.total_users;
        } catch (error) {
            this.rootStore.authStore.handleUnauthorized(error);
        }
    }
}

export default ConcertStore;
