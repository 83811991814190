import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import loadable from './utils/loadable';
import Loader from './components/commonComponents/Loader';
import Layout from './components/Layout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoutes from './utils/ProtectedRoutes';

const Login = loadable(() => import('./components/Login'), <Loader />)
const ForgotPassword = loadable(() => import('./components/ForgotPassword'), <Loader />)
const Dashboard = loadable(() => import('./components/Dashboard'), <Loader />)
const Bootleg = loadable(() => import('./components/bootleg/Bootleg'), <Loader />)
const AddUpdateBootleg = loadable(() => import('./components/bootleg/AddUpdateBootleg'), <Loader />)
const ChangePassword = loadable(() => import('./components/ChangePassword'), <Loader />)
const Profile = loadable(() => import('./components/profile/Profile'), <Loader />)
const Customers = loadable(() => import('./components/customer/Customers'), <Loader />)
const Transactions = loadable(() => import('./components/transaction/Transactions'), <Loader/>)
const CustomerProfile = loadable(() => import('./components/customer/CustomerProfile'), <Loader />)
const Concerts = loadable(() => import('./components/concerts/Concerts'), <Loader />)
const AddUpdateConcert = loadable(() => import('./components/concerts/AddUpdateConcerts'), <Loader />)
const ManageTracks = loadable(() => import('./components/concerts/ManageTracks'), <Loader />)
const Price = loadable(() => import('./components/price/Price'), <Loader/>)
const AddUpdatePrice = loadable(() => import('./components/price/AddUpdatePrice'), <Loader/>)
const CustomerTransactionHistory = loadable(() => import('./components/customer/CustomerTransactionHistory'), <Loader />)
const Gallery = loadable(() => import('./components/concerts/Gallery'), <Loader />)

const Artist = loadable(() => import('./components/artists/Artist'), <Loader />)
const AddUpdateArtist = loadable(() => import('./components/artists/AddUpdateArtist'), <Loader />)
const Venue = loadable(() => import('./components/venue/Venue'), <Loader />)
const AddUpdateVenue = loadable(() => import('./components/venue/AddUpdateVenue'), <Loader />)
const Photographer = loadable(() => import('./components/photographers/Photographer'), <Loader />)
const AddUpdatePhotographer = loadable(() => import('./components/photographers/AddUpdatePhotographer'), <Loader />)
function App() {
  return (
    <>
      <ToastContainer
        pauseOnFocusLoss={false}
        hideProgressBar={true}
      />
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="bootlegs" element={<ProtectedRoutes><Bootleg /></ProtectedRoutes>} />
            <Route path="bootlegs/add" element={<ProtectedRoutes><AddUpdateBootleg /></ProtectedRoutes>} />
            <Route path="bootlegs/update/:id" element={<ProtectedRoutes><AddUpdateBootleg /></ProtectedRoutes>} />
            <Route path="customers" element={<ProtectedRoutes><Customers /></ProtectedRoutes>} />
            <Route path="customers/profile/:id" element={<ProtectedRoutes><CustomerProfile /></ProtectedRoutes>} />
            <Route path="customers/transactions/:id" element={<ProtectedRoutes><CustomerTransactionHistory /></ProtectedRoutes>} />
            <Route path="artists" element={<Artist />} />
            <Route path="artists/add" element={<AddUpdateArtist />} />
            <Route path="artists/update/:id" element={<AddUpdateArtist />} />
            <Route path="venues" element={<Venue />} />
            <Route path="venues/add" element={<AddUpdateVenue />} />
            <Route path="venues/update/:id" element={<AddUpdateVenue />} />
            <Route path="photographers" element={<Photographer />} />
            <Route path="photographers/add" element={<AddUpdatePhotographer />} />
            <Route path="photographers/update/:id" element={<AddUpdatePhotographer />} />
            <Route path="user/change-password" element={<ChangePassword />} />
            <Route path="user/profile" element={<Profile />} />
            <Route path='transactions' element={<ProtectedRoutes><Transactions/></ProtectedRoutes>}></Route>
            <Route path="concerts" element={<Concerts />} />
            <Route path="concerts/add" element={<AddUpdateConcert />} />
            <Route path="concerts/update/:id" element={<AddUpdateConcert />} />
            <Route path="concerts/:concertId/tracks" element={<ManageTracks />} />
            <Route path="concerts/:concertId/gallery" element={<Gallery />} />
            <Route path="prices" element={<Price/>}/>
            <Route path="prices/add" element={<ProtectedRoutes><AddUpdatePrice/></ProtectedRoutes>}/>
            <Route path="prices/update/:id" element={<ProtectedRoutes><AddUpdatePrice/></ProtectedRoutes>}/>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
