import { makeObservable } from "mobx";
import { IAuthStore, IBootlegStore, ICustomerStore, ITransactionHistoryStore,IConcertStore, Store, IDashboardStore, IPriceStore, IArtistStore, IVenueStore, IPhotographerStore } from "../global/types";
import AuthStore from "./AuthStore";
import BootlegStore from "./BootlegStore";
import CustomerStore from "./CustomerStore";
import TransactionHistoryStore from "./TransactionHistoryStore" 
import ConcertStore from "./ConcertStore";
import DashboardStore from "./DashboardStore";
import PriceStore from "./PriceStore";
import ArtistStore from "./ArtistStore";
import VenueStore from "./VenueStore";
import PhotographerStore from "./PhotographerStore";

class RootStore implements Store {
    public authStore: IAuthStore
    public bootlegStore: IBootlegStore
    public artistStore: IArtistStore
    public venueStore: IVenueStore
    public photographerStore: IPhotographerStore;
    public customerStore: ICustomerStore
    public transactionHistoryStore: ITransactionHistoryStore
    public concertStore: IConcertStore
    public dashboardStore: IDashboardStore
    public priceStore: IPriceStore

    constructor() {
        makeObservable(this);
        this.authStore = new AuthStore(this);
        this.bootlegStore = new BootlegStore(this);
        this.artistStore = new ArtistStore(this);
        this.venueStore = new VenueStore(this);
        this.photographerStore = new PhotographerStore(this)
        this.customerStore = new CustomerStore(this);
        this.transactionHistoryStore = new TransactionHistoryStore(this);
        this.concertStore = new ConcertStore(this);
        this.dashboardStore = new DashboardStore(this)
        this.priceStore = new PriceStore(this)
    }
}

export default RootStore;
