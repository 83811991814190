import axios, { AxiosResponse } from "axios";
import { PriceData } from "../global/types";
import { Dictionary } from "lodash";

export const getAllPriceData = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/price/list?page=${data.page}&limit=${data.limit}&search=${data.search}`;
    return axios.get(url);
}

export const addPrice = async (data: PriceData): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/price/add`;
    return axios.post(url, data);
}

export const getOnePriceData = async (id: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/price/details/${id}`;
    return axios.get(url);
}

export const updatePriceData = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/price/update`;
    return axios.post(url, data);
}