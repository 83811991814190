import React, { lazy, Suspense } from "react";

const loadable = (
  importFunc: () => Promise<{ default: React.ComponentType<any> }>,
  fallback: React.ReactElement | null
): React.FC => {
  const LazyComponent = lazy(importFunc);

  return (props: any) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
};

export default loadable;
