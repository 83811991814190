import { makeAutoObservable, runInAction } from "mobx";
import { ICustomerStore, Store, AllUserData, AllUserTransactionHistoryData } from "../global/types";
import { getAllCustomersData, getOneUserData } from "../api/user.action";
import { getAllTransactionHistoryData } from "../api/transactionHistoryAction";

class CustomerStore implements ICustomerStore {
    rootStore: Store;
    allCustomersData: AllUserData[] = [];
    allUserTransactionHistoryData: AllUserTransactionHistoryData[] = [];
    customerData: AllUserData | null = null;
    error: string | null = null;
    loading: boolean = false;
    page: number = 1;
    searchTerm: string = '';
    limit: number = 10;
    logInType: string = '';
    totalItems: number = 0;
    totalPages: number = 0;
    userTransactionPage: number = 1
    userTransactionSearchTerm: string = ''
    userTransactionLimit: number = 10
    userTransactionStatus: string = '';
    userTransactionConcertTitle: string = ''
    userTransactionStartDate: string | Date = '';
    userTransactionEndDate: string | Date = '' ;
    userTransactionTotalItems: number = 0;
    userTransactionTotalPages: number = 0;
    customerId: string = '';

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    fetchAllCustomersData = async (
        action: 'change_page' | 'search' | 'change_limit' | 'change_login_type' | '',
        page: number,
        searchTerm: string,
        limit: number,
        logInType: string
    ) => {
        this.setLoading(true);
        try {
            runInAction(() => {
                switch (action) {
                    case 'change_page':
                        this.page = page;
                        break;
                    case 'search':
                        this.searchTerm = searchTerm;
                        this.page = 1;
                        break;
                    case 'change_limit':
                        this.limit = limit
                        this.page = 1
                        break;
                    case 'change_login_type':
                        this.logInType = logInType
                        this.page = 1
                        break;
                    default:
                        break;
                }
            })
            const data = {
                page: this.page,
                limit: this.limit,
                search: this.searchTerm?.trim(),
                login_type: this.logInType.toUpperCase()
            }
            const response = await getAllCustomersData(data);
            runInAction(() => {
                this.allCustomersData = response.data?.data?.users || [];
                this.totalItems = response.data?.data?.totalItems;
                this.totalPages = response.data?.data?.totalPages;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    fetchAllUserTransactionHistoryData = async (
        action: 'change_page' | 'search' | 'change_limit' | 'change_transaction_status' | 'change_concert_title' | 'change_date_range' |'',
        userTransactionPage: number,
        userTransactionSearchTerm: string,
        userTransactionLimit: number,
        userTransactionStatus: string,
        userTransactionConcertTitle: string,
        userTransactionStartDate: string | Date,
        userTransactionEndDate: string | Date,
        customerId?: string
    ) => {
        this.setLoading(true);
        try {
            runInAction(() => {
                switch (action) {
                    case 'change_page':
                        this.userTransactionPage = userTransactionPage;
                        break;
                    case 'search':
                        this.userTransactionSearchTerm = userTransactionSearchTerm;
                        this.userTransactionPage = 1;
                        break;
                    case 'change_limit':
                        this.userTransactionLimit = userTransactionLimit
                        this.userTransactionPage = 1
                        break;
                    case 'change_transaction_status':
                        this.userTransactionStatus = userTransactionStatus
                        this.userTransactionPage = 1
                        break;
                    case 'change_concert_title':
                        this.userTransactionConcertTitle = userTransactionConcertTitle
                        this.userTransactionPage = 1
                        break;
                    case 'change_date_range':
                        this.userTransactionStartDate = userTransactionStartDate
                        this.userTransactionEndDate = userTransactionEndDate
                        this.userTransactionPage = 1
                        break;
                    default:
                        break;
                }
                this.customerId = customerId || ''
            })
            const data = {
                page: userTransactionPage,
                limit: this.userTransactionLimit,
                search: this.userTransactionSearchTerm?.trim(),
                transaction_status: this.userTransactionStatus.toUpperCase(),
                concert_id: this.userTransactionConcertTitle,
                start_date: this.userTransactionStartDate,
                end_date: this.userTransactionEndDate,
                customer_id: this.customerId
            }
            const response = await getAllTransactionHistoryData(data);
            runInAction(() => {
                this.allUserTransactionHistoryData = response.data?.data?.transactions || []
                this.userTransactionTotalItems = response.data?.data?.totalItems;
                this.userTransactionTotalPages = response.data?.data?.totalPages;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    fetchOneCustomerData = async (id: string) => {
        this.setLoading(true);
        try {
            const response = await getOneUserData(id);
            runInAction(() => {
                this.customerData = response.data.data;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    resetCustomerPageDataStates = () => {
        runInAction(() => {
            this.allCustomersData = [];
            this.allUserTransactionHistoryData = []
            this.customerData = null;
            this.error = null;
            this.loading = false;
            this.page = 1;
            this.searchTerm = '';
            this.limit = 10;
            this.logInType = '';
            this.totalItems = 0;
            this.totalPages = 0;
            this.userTransactionPage = 1
            this.userTransactionSearchTerm = ''
            this.userTransactionLimit = 10
            this.userTransactionStatus = '';
            this.userTransactionConcertTitle = ''
            this.userTransactionStartDate  = '';
            this.userTransactionEndDate  = '' ;
            this.userTransactionTotalItems = 0;
            this.userTransactionTotalPages = 0;
            this.customerId = '';
        })
    }
}

export default CustomerStore;
